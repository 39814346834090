interface IOptionsCalculateCpfDigitsSum {
  start: number;
  end: number;
}

const calculateCpfDigitsSum = (
  values: number[],
  options: IOptionsCalculateCpfDigitsSum
): number => {
  const { start, end } = options;

  return values
    .slice(start, end)
    .reduce((acc, num, i) => acc + num * (end + 1 - i), 0);
};

const calculateCpfRemainder = (sum: number): number => {
  const remainder = (sum * 10) % 11;

  return remainder === 10 || remainder === 11 ? 0 : remainder;
};

const validateCpf = (value: string): boolean => {
  const cpf = value.replace(/[^\d]/g, "").split("").map(Number);
  const isSameDigits = cpf.every((digit) => digit === cpf[0]);

  if (isSameDigits) {
    return false;
  }

  const totalUpToNineDigit = calculateCpfDigitsSum(cpf, {
    start: 0,
    end: 9,
  });

  const totalUpToNineDigitRemainder = calculateCpfRemainder(totalUpToNineDigit);

  const totalUpToTenDigit = calculateCpfDigitsSum(cpf, {
    start: 0,
    end: 10,
  });

  const totalUpToTenDigitRemainder = calculateCpfRemainder(totalUpToTenDigit);

  return (
    totalUpToNineDigitRemainder === cpf[9] &&
    totalUpToTenDigitRemainder === cpf[10]
  );
};

const validateCnpj = (value: string): boolean => {
  const cnpj = value.replace(/[^\d]/g, "");
  const sameDigitsRegex = /^(\d)\1*$/;

  if (sameDigitsRegex.test(cnpj)) {
    return false;
  }

  if (cnpj.length !== 14) {
    return false;
  }

  let sum = 0;
  let multiplier = 5;

  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * multiplier;

    if (multiplier === 2) {
      multiplier = 9;
    } else {
      multiplier--;
    }
  }

  let remainder = sum % 11;
  const verificationDigit1 = remainder < 2 ? 0 : 11 - remainder;

  if (verificationDigit1 !== parseInt(cnpj.charAt(12))) {
    return false;
  }

  sum = 0;
  multiplier = 6;

  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * multiplier;

    if (multiplier === 2) {
      multiplier = 9;
    } else {
      multiplier--;
    }
  }

  remainder = sum % 11;
  const verificationDigit2 = remainder < 2 ? 0 : 11 - remainder;

  if (verificationDigit2 !== parseInt(cnpj.charAt(13))) {
    return false;
  }

  return true;
};

export const checkCpfAndCnpj = (value: string | undefined): boolean => {
  if (!value) return false;
  const newValue = value.replace(/[^\d]/g, "");

  const isCpf = /^(\d{11})$/.test(newValue);
  const isCnpj = /^(\d{14})$/.test(newValue);

  if (isCpf) {
    return validateCpf(newValue);
  } else if (isCnpj) {
    return validateCnpj(newValue);
  }

  return false;
};

export const naiveCheckCpfAndCnpj = (value: string) => {
  const cpfCnpjPattern =
    /^(?:(?:\d{3}\.){2}\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/;
  return cpfCnpjPattern.test(value);
};
